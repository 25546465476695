































































































































































































































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import User from '@/models/User';
import {namespace} from 'vuex-class';
import {Permission} from '@/misc/enums/permission.enum';
import Substitute, {SubstituteData} from '@/models/Substitute';
import {KeyOf} from '@/misc/Parseable';
import {jobStoreActions} from '@/stores/job.store';
import Job from '@/models/Job';
import JobsFilterData from '@/misc/JobsFilterData';
import moment, {Moment} from 'moment';
import {showTimes, TimeDuration} from '@/helper/TimeDuration';
import {sortArray} from '@/helper/Arrays';
import WorkSession from '@/models/WorkSession';
import {FindAllResponse} from '@/interfaces/Responses';

const UserStore = namespace('user');
const JobStore = namespace('job');

interface Timeslot {
  start: string;
  end: string;
  user: User | null;
  substitutes: SubstituteData[];
}

interface SortOptions {
  page: number;
  itemsPerPage: number;
  sortBy: string[];
  sortDesc: boolean[];
  groupBy: string[];
  groupDesc: boolean[];
  multiSort: boolean;
  mustSort: boolean;
}

@Component({
  computed: {
    Permission() {
      return Permission;
    },
  },
  components: {
    MenuWithPicker: () =>
      import('@/components/shared/MenuWithPicker.component.vue'),
    RJAutocomplete: () =>
      import('@/components/shared/custom-vuetify/RJAutocomplete.vue'),
    UserInitialsComponent: () => import(
      '@/components/user/UserInitials.component.vue'),
  },
})
export default class SubstituteComponent extends Vue {

  @UserStore.Action('loadUsersAction')
  private loadUsersAction!: (payload: { companyId: string }) => Promise<User[]>;
  @UserStore.Action('createSubstituteAction')
  private createSubstituteAction!: (substituteBody: Substitute) => Promise<Substitute>;
  @UserStore.Action('updateSubstituteAction')
  private editSubstituteAction!: (payload: { id: string } & Partial<Substitute>) => Promise<Substitute>;
  @UserStore.Action('deleteSubstituteAction')
  private deleteSubstituteAction!: (id: string) => Promise<void>;
  @UserStore.Action('loadSubstitutesAction')
  private loadSubstitutesAction!: (payload: {
    userId: string,
    substituteUserId: string,
    populate?: KeyOf<Substitute>[],
  }) => Promise<FindAllResponse<Substitute>>;

  @JobStore.Action(jobStoreActions.LOAD_JOBS_ACTION)
  private loadJobAction!: (payload: { filterData: JobsFilterData, discardCache: boolean }) => Promise<Job[]>;

  @UserStore.Getter('users')
  private _users!: User[];

  @UserStore.Getter('substitutes')
  private _substitutes!: Substitute[];

  private get substitutes(): Substitute[] {
    return this._substitutes;
  }

  private ownSubs: Substitute[] = [];

  private foreignSubs: Substitute[] = [];

  @Prop({required: true})
  private user!: User;

  private showCreationDialog: boolean = false;
  private showEditDialog: boolean = false;
  private showDeleteDialog: boolean = false;

  private loading: boolean = false;

  private sortOptions: SortOptions = {
    page: 0,
    groupBy: [],
    groupDesc: [],
    itemsPerPage: 1,
    multiSort: false,
    sortBy: [],
    mustSort: false,
    sortDesc: [],
  };

  private get now() {
    return moment();
  }

  private get tableHeaders() {
    return [{
      text: this.$t('GENERAL.CLEANING_LOCATION'),
      value: 'location',
      width: '20%',
    }, {
      text: this.$t('CLEANTIME_OVERVIEW.HEADER.DATE'),
      value: 'date',
      width: '20%',
    }, {
      text: this.$t('GENERAL.DATE_FROM'),
      value: 'startTime',
      width: '10%',
    }, {
      text: this.$t('GENERAL.DATE_TO'),
      value: 'endTime',
      width: '10%',
    }, {
      text: '',
      value: 'actions',
      sortable: false,
      width: '10%',
    },
    ];
  }

  private get ownTableHeaders(): any {
    return [
      {
        text: this.$t('USER_DETAIL.TABS.SUBSTITUTE'),
        value: 'substituteUser',
        width: '20%',
      },
    ].concat(this.tableHeaders);
  }

  private get foreignTableHeaders(): any {
    return [
      {
        text: this.$t('USER_DETAIL.FOREIGN_SUBSTITUTE'),
        value: 'substituteUser',
        width: '20%',
      },
    ].concat(this.tableHeaders);
  }

  /**
   * The dates, where the origin user is not available
   * @private
   */
  private timeslots: Timeslot[] = [{
    start: '',
    end: '',
    user: null,
    substitutes: [],
  }];

  private substituteToEdit: Substitute | null = null;

  public async mounted() {
    const promises: Promise<any>[] = [this.loadSubstitutesAction({
      userId: this.user.id!,
      substituteUserId: this.user.id!,
      populate: ['substituteUser', 'cleanTime'],
    })];
    if (!this.possibleSubstitutes.length) {
      promises.push(this.loadUsersAction({companyId: this.$route.params.companyId}));
    }
    await Promise.all(promises);
    this.assignSubstitutes();
  }

  private assignSubstitutes() {
    this.ownSubs = this.substitutes.filter((sub: Substitute) => sub.userId === this.user.id);
    this.foreignSubs = this.substitutes.filter((sub: Substitute) => sub.substituteUserId === this.user.id);
  }

  private get possibleSubstitutes(): User[] {
    return this._users.filter((substitute: User) => substitute.id !== this.user.id).sort((a, b) => a.fullName > b.fullName ? 1 : -1);
  }

  private get possibleSubsToAssign() {
    return this.possibleSubstitutes.filter((substitute: User) => this.substituteToEdit ? substitute.id !== this.substituteToEdit.userId : true);
  }

  private fullName(user: User) {
    return user.fullName;
  }

  private async loadJobsForTimeslot(slot: Timeslot) {
    const start = moment(slot.start);
    const end = moment(slot.end);
    if (start.isValid() && end.isValid()) {
      const filterData = new JobsFilterData({
        company: this.$route.params.companyId,
        users: [this.user.id!],
        locations: [],
        managers: [],
        dates: new Array(end.diff(start, 'days')).fill(0).map((_, index) => start.clone().add(index, 'days').toISOString()),
      });
      this.loading = true;
      slot.substitutes = (await this.loadJobAction({
        filterData,
        discardCache: true,
      }))
        // filter, to not create substitutes that are more than two hours on work, or is already finished
        .filter((job: Job) =>
          moment(job.cleanTimeOccurrence.start).diff(this.now, 'hours') > -2 &&
          moment(job.cleanTimeOccurrence.end).isAfter(this.now) &&
          // if it has already a workSession for the user, don't show it
          !job.workSessions.some((ws: WorkSession) => ws.userId === this.user.id))
        .map((job: Job) => ({
          location: job.location,
          customer: job.customer!,
          substitute: Substitute.parseFromObject({
            startDate: moment(job.cleanTimeOccurrence.start).toDate(),
            endDate: moment(job.cleanTimeOccurrence.end).toDate(),
            companyId: this.$route.params.companyId,
            cleanTimeId: job.cleanTime?.id,
            userId: this.user.id,
            substituteUserId: slot.user?.id,
            substituteUser: slot.user ?? undefined,
          }),
        }));
      this.loading = false;
    }
  }

  private async onEditSubstitute(sub: Substitute) {
    this.substituteToEdit = Substitute.parseFromObject(sub);
    this.showEditDialog = true;
  }

  private async onDeleteSubstitute(sub: Substitute) {
    this.substituteToEdit = Substitute.parseFromObject(sub);
    this.showDeleteDialog = true;
  }

  private assignUserToTimeslot(event: string, slot: Timeslot) {
    slot.user = this.possibleSubstitutes.find((user: User) => user.id === event)!;
    slot.substitutes.forEach((sub: SubstituteData) => {
      sub.substitute.substituteUserId = event;
      sub.substitute.substituteUser = slot.user!;
    });
    // UI does not render automatically, but now it works
    this.$forceUpdate();
  }

  private changeUser(subUser: User, sub: Substitute) {
    sub.substituteUserId = subUser.id!;
    sub.substituteUser = subUser;
  }

  private async createNewSubstitute() {
    try {
      await Promise.all(
        this.timeslots.map((slot: Timeslot) =>
          slot.substitutes.map((subData: SubstituteData) =>
            this.createSubstituteAction(subData.substitute)),
        ).flat());
      this.assignSubstitutes();
      this.$notifySuccessSimplified('USER_DETAIL.NOTIFICATIONS.SUBSTITUTE.CREATE.SUCCESS');
    } catch (e) {
      this.$notifyErrorSimplified('USER_DETAIL.NOTIFICATIONS.SUBSTITUTE.CREATE.FAILURE');
    } finally {
      this.showCreationDialog = false;
      // reset timeslots
      this.timeslots = [{
        start: '',
        end: '',
        user: null,
        substitutes: [],
      }];
    }
  }

  private async updateSubstitute() {
    try {
      await this.editSubstituteAction(this.substituteToEdit!);
      this.assignSubstitutes();
      this.$notifySuccessSimplified('USER_DETAIL.NOTIFICATIONS.SUBSTITUTE.UPDATE.SUCCESS');
      this.substituteToEdit = null;
    } catch (e) {
      this.$notifyErrorSimplified('USER_DETAIL.NOTIFICATIONS.SUBSTITUTE.UPDATE.FAILURE');
    } finally {
      this.showEditDialog = false;
    }
  }

  private async deleteSubstitute() {
    try {
      await this.deleteSubstituteAction(this.substituteToEdit!.id);
      this.assignSubstitutes();
      this.$notifySuccessSimplified('USER_DETAIL.NOTIFICATIONS.SUBSTITUTE.DELETE.SUCCESS');
      this.substituteToEdit = null;
    } catch (e) {
      this.$notifyErrorSimplified('USER_DETAIL.NOTIFICATIONS.SUBSTITUTE.DELETE.FAILURE');
    } finally {
      this.showDeleteDialog = false;
    }
  }

  private displaySubName(subUserId: string | null) {
    return this.possibleSubstitutes.find((user: User) => user.id === subUserId)?.fullName ?? '';
  }

  private showTime = (date: string | Date | Moment, dateEnd: string | Date | Moment) => showTimes(TimeDuration.fromAny(date, dateEnd));

  private addTimeslot() {
    this.timeslots.push({start: '', end: '', substitutes: [], user: null});
  }

  private removeTimeslot(index: number) {
    this.timeslots[index].start = '';
    this.timeslots[index].end = '';
    this.timeslots.splice(index, 1);
  }

  private getMinStart(startDate: string) {
    return startDate ? moment(startDate).add(2, 'days').toISOString() : this.now.toISOString();
  }

  private removeSubstitute(slotIndex: number, subIndex: number) {
    delete this.timeslots[slotIndex].substitutes[subIndex].substitute.substituteUser;
    delete this.timeslots[slotIndex].substitutes[subIndex].substitute.substituteUserId;
  }

  private removeOccurrence(slotIndex: number, subIndex: number) {
    this.timeslots[slotIndex].substitutes.splice(subIndex, 1);
  }

  private customSort(
    options: SortOptions,
    arrayToSort: 'ownSubs' | 'foreignSubs') {
    switch (options.sortBy[0]) {
      case 'location':
        sortArray(this[arrayToSort], options.sortDesc[0], (el) => el.cleanTime?.location?.name!);
        break;
      case 'substituteUser':
        sortArray(this[arrayToSort], options.sortDesc[0], (el) => el.substituteUser?.fullName!);
        break;
      case 'startTime':
      case 'date':
        sortArray(this[arrayToSort], options.sortDesc[0], (el) => new Date(el.startDate).getTime());
        break;
      case 'endTime':
        sortArray(this[arrayToSort], options.sortDesc[0], (el) => new Date(el.endDate).getTime());
        break;
    }
  }
}
